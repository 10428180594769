import React from 'react';

const Card = ({ item }) => {
  return (
    <div
      onClick={() => {
        window.open(item.href);
      }}
      className={`border-4 cursor-pointer border-black shadow-greenBox flex items-center ${
        item.icon ? 'justify-between px-4' : 'justify-center'
      } h-20`}
    >
      {/* <div className="border-r-4 border-black w-20 flex items-center justify-center">
        <img
          src={process.env.PUBLIC_URL + item.image}
          alt={item.title}
          className="h-16 rounded my-4 md:my-0"
        />
      </div> */}
      {item.icon && item.icon}
      <h3 className="md:text-3xl text-2xl">{item.title}</h3>
    </div>
  );
};

export default Card;
