import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import React from 'react';
import Card from './components/Card';
import Navbar from './components/Navbar';

const App = () => {
  return (
    <div>
      <Navbar />
      <div
        id="home"
        className="pb-16 sm:pb-0 sm:h-80vh mt-20 border-b-4 border-green-600"
      >
        <div className="md:hidden px-4">
          <div className="w-full flex justify-center items-center lg:bg-green-200 rounded-md">
            <img
              alt="man delivering"
              src={process.env.PUBLIC_URL + 'man-delivering.png'}
              className="h-80"
            />
          </div>
        </div>
        <div className="mx-auto max-w-7xl flex items-center px-8">
          <div className="w-full md:w-1/2 md:py-32">
            <h1 className="text-black text-5xl lg:text-6xl mb-4">
              At <span className="text-green-600">Kustoma</span> we provide
              supplies to food{' '}
              <span className="text-green-600">businesses</span>
            </h1>
            <p className="text-lg">
              We’re a procurement service, tailored to helping hospitality
              businesses meet the growing consumer demand.
              {/* <br />
              We help you grow your business by giving you loans to stock up on
              supplies. */}
            </p>
            <div
              onClick={() => window.open('https://forms.gle/V6LYybQW1V1cG7hU7')}
              className="flex justify-center items-center bg-green-600 hover:bg-green-500 h-12 w-40 mt-8 cursor-pointer"
            >
              <span className="text-white">Get Started</span>
            </div>
          </div>
          <div className="hidden md:flex w-1/2 py-16 px-4 lg:px-16">
            <div className="w-full flex justify-center items-center lg:bg-green-200 rounded-md">
              <img
                src={process.env.PUBLIC_URL + 'man-delivering.png'}
                alt="man delivering"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="w-full px-8">
        <div className="mx-auto max-w-4xl my-16 md:text-center">
          <h1 className="text-black text-5xl lg:text-6xl tracking-wide">
            What we offer
          </h1>
          <p className="">The advantages of joining our team</p>
        </div>

        {/*  */}

        <div className="flex flex-col md:flex-row justify-between items-center my-8 mx-auto max-w-4xl">
          <div className="max-w-sm">
            <h2 className="text-gray-700 text-5xl lg:text-6xl mb-4">
              Cost-effective
              <br />
              Procurement
            </h2>
            <p className="text-lg">
              We procure{' '}
              <span className="text-green-600 font-semibold">everything</span>{' '}
              and we always strive to get the best deals. We get you the best
              products at the best prices by procuring directly from farms and
              manufacturers.
            </p>
          </div>

          <img
            src={process.env.PUBLIC_URL + 'Piggy bank_Monochromatic.svg'}
            alt="Reduce Costs"
            className="h-80 rounded my-4 md:my-0"
          />
        </div>

        <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 mx-auto max-w-4xl">
          <div className="max-w-sm">
            <h2 className="text-gray-700 text-5xl lg:text-6xl mb-4">
              Quality
              <br />
              Assessment
            </h2>
            <p className="text-lg">
              We ensure your products are of the highest quality before getting
              to your doorstep
            </p>
          </div>

          <img
            src={process.env.PUBLIC_URL + 'Search Engine_Two Color.svg'}
            alt="Reduce Costs"
            className="h-80 rounded my-4 md:my-0"
          />
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center my-8 mx-auto max-w-4xl">
          <div className="max-w-sm">
            <h2 className="text-gray-700 text-5xl lg:text-6xl mb-4">
              Pay on Credit
            </h2>
            <p className="text-lg">
              Let's handle the procurement while you run your business and pay
              us back with a flexible payment plan within 7-10 days.
            </p>
          </div>

          <img
            src={process.env.PUBLIC_URL + 'Accountant_Flatline.svg'}
            alt="Reduce Costs"
            className="h-80 rounded my-4 md:my-0"
          />
        </div>
        <div className="flex flex-col md:flex-row-reverse justify-between items-center my-8 mx-auto max-w-4xl">
          <div className="max-w-sm">
            <h2 className="text-gray-700 text-5xl lg:text-6xl mb-4">
              Dependability & Trust
              <br />
            </h2>
            <p className="text-lg">
              We connect your business to the industry leading distribution
              network and inventory management, always keeping you stocked with
              the freshest products.
            </p>
          </div>

          <img
            src={process.env.PUBLIC_URL + 'World Connection _Two Color.svg'}
            alt="Reduce Costs"
            className="h-80 rounded my-4 md:my-0"
          />
        </div>
      </div>

      <div id="supply" className="w-full my-16 px-8">
        <div className="flex flex-col md:flex-row justify-between items-center mx-auto max-w-4xl">
          <div className="max-w-sm md:w-1/2 md:pr-4">
            <h1 className="text-black text-5xl lg:text-6xl">Our Supply</h1>
            <p>
              We proudly partner with local farmers and manufacturers to bring
              you the freshest produce. We support farm-to-table initiatives to
              deliver the best products directly to your doorstep. Our goal is
              to be your one reliable supplier for everything
            </p>
          </div>
          <div className="md:w-1/2 my-4 md:my-0 w-full grid grid-cols-2 gap-4">
            <Card item={{ title: 'Poultry' }} />
            <Card item={{ title: 'Seafood' }} />
            <Card item={{ title: 'Drinks' }} />
            <Card item={{ title: 'Vegetables' }} />
            <Card item={{ title: 'Fruits' }} />
            <Card item={{ title: 'Canned Food' }} />
            <Card item={{ title: 'Condiments' }} />
            <Card item={{ title: 'Utensils' }} />
          </div>
        </div>
      </div>

      <div id="contact" className="w-full my-16 px-8">
        <div className="flex flex-col justify-center items-center mx-auto max-w-4xl">
          <h1 className="text-black text-5xl lg:text-6xl">Talk to Us</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 my-8 w-full ">
            <div className="w-full my-2">
              <Card
                item={{
                  title: '0701 707 2724',
                  href: 'https://wa.me/2347017072724',
                  icon: (
                    <img
                      src={process.env.PUBLIC_URL + 'whatsapp.svg'}
                      alt="Logo"
                      className="h-6 w-6 rounded my-4 md:my-0"
                    />
                  ),
                }}
              />
            </div>
            <div className="w-full my-2">
              <Card
                item={{
                  title: 'hello@kustoma.io',
                  icon: <MailIcon className="h-6 w-6" />,
                  href: 'mailto:hello@kustoma.io',
                }}
              />
            </div>
          </div>
          <div
            onClick={() => window.open('https://forms.gle/V6LYybQW1V1cG7hU7')}
            className="flex justify-center items-center bg-green-600 p-4 my-4 cursor-pointer tracking-wide uppercase rounded shadow hover:shadow-lg"
          >
            <span className="text-white text-2xl">Send us a message</span>
          </div>
        </div>
      </div>

      <div className="mt-16 bg-green-100 py-4 px-2 sm:px-16 flex flex-wrap justify-between items-center">
        <img
          src={process.env.PUBLIC_URL + 'logo.png'}
          alt="Logo"
          className="h-14 w-14 rounded my-4 md:my-0"
        />
        <h1 className="text-3xl">
          Kustoma<span className="text-sm absolute">®</span>
        </h1>
        <a href="https://www.facebook.com/Kustoma.io/">
          <img
            src={process.env.PUBLIC_URL + 'facebook.svg'}
            alt="Logo"
            className="h-8 w-8 rounded my-4 md:my-0"
          />
        </a>
        <a href="https://www.instagram.com/kustoma.io/">
          <img
            src={process.env.PUBLIC_URL + 'instagram.svg'}
            alt="Logo"
            className="h-8 w-6 rounded my-4 md:my-0"
          />
        </a>
        <a href=" tel:+2347017072724">
          <PhoneIcon className="h-8 w-8" />
        </a>
      </div>
    </div>
  );
};

export default App;
