import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';

const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const navigation = [
    { name: 'Home', href: '#home' },
    { name: 'Services', href: '#services' },
    { name: 'Supply', href: '#supply' },
    { name: 'Contact Us', href: '#contact' },
  ];

  window.onscroll = function () {
    if (window.pageYOffset !== 0) {
      setIsActive(true);
    }

    if (window.pageYOffset === 0 && isActive) {
      setIsActive(false);
    }
  };

  return (
    <>
      <nav
        className={`fixed top-0 w-screen max-w-full flex px-6 items-center justify-between bg-white ${
          isActive ? 'shadow-md' : ''
        } h-20 z-40`}
      >
        <div className="w-16">
          <a href="/" className="text-center">
            <h1 className="text-3xl tracking-wide text-green-600">Kustoma</h1>
          </a>
        </div>
        <div className="hidden md:block">
          <ul className="flex tracking-wide space-x-6">
            {navigation.map((item, id) => (
              <li key={item.name}>
                <a
                  className="font-normal hover:text-green-600"
                  href={item.href}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div
          onClick={() => window.open('https://forms.gle/V6LYybQW1V1cG7hU7')}
          className="hidden md:flex justify-center items-center space-x-3 rounded-md w-24 h-10 bg-green-600 cursor-pointer"
        >
          <span className="text-gray-100 uppercase">Sign up</span>
        </div>
        <div className="md:hidden">
          {navbarOpen ? (
            <ArrowSmUpIcon
              onClick={() => setNavbarOpen(!navbarOpen)}
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          ) : (
            <ArrowSmDownIcon
              onClick={() => setNavbarOpen(!navbarOpen)}
              className="h-6 w-6 text-green-600"
              aria-hidden="true"
            />
          )}
        </div>
      </nav>
      {navbarOpen && (
        <ul className="fixed md:hidden bg-white shadow-lg w-screen space-y-4 p-6 z-40">
          {navigation.map((item, id) => (
            <li
              key={item.name}
              className="font-semibold hover:text-green-700 tracking-wide"
            >
              <a href={item.href}>{item.name}</a>
            </li>
          ))}
          <hr />
          <li className=" font-semibold text-green-600 tracking-wide text-right">
            <a
              href="https://forms.gle/V6LYybQW1V1cG7hU7"
              target="_blank"
              rel="noreferrer"
            >
              Sign Up
            </a>
          </li>
        </ul>
      )}
    </>
  );
};

export default Navbar;
